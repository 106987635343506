import React from "react";
import Header from "../components/Header";
import Quote from "../components/Quote";
import Layout, { PageLayout } from "../components/Layout";
import {
  MaybeStickyTitle,
  Title,
  TitleDescription,
} from "../components/Titles";
import Nest from "../components/Nest";
import { Email, Github, Twitter, LinkedIn, RSS } from "../components/Social";
import { ImageItem, Contain, LinksLayout } from "../components/ImageContainer";

const Links = () => (
  <Contain>
    <LinksLayout>
      <ImageItem
        href="https://github.com/iammerrick"
        image={<Github height={160} />}
        description="Github"
      />
      <ImageItem
        href="https://twitter.com/iammerrick"
        image={<Twitter height={160} />}
        description="Twitter"
      />
      <ImageItem
        href="https://www.linkedin.com/in/iammerrick/"
        image={<LinkedIn height={160} />}
        description="LinkedIn"
      />
      <ImageItem
        href="mailto:merrick.christensen@gmail.com"
        image={<Email height={160} />}
        description="Email"
      />
      <ImageItem
        href="/articles.xml"
        image={<RSS height={160} />}
        description="RSS Feed"
      />
    </LinksLayout>
  </Contain>
);

class FollowPage extends React.Component {
  render() {
    return (
      <PageLayout slug={this.props.location.pathname}>
        <Nest>
          <Header />
          <Quote
            content="While we have the privilege of speaking to each other, let us speak words of comfort and consolation."
            source="Brigham Young"
          />
          <Layout
            left={
              <MaybeStickyTitle>
                <Title>Follow me, in the modern sense of the word.</Title>
                <TitleDescription>
                  Not in real life. That would probably be super creepy and
                  stuff.
                </TitleDescription>
              </MaybeStickyTitle>
            }
            right={<Links />}
          />
        </Nest>
      </PageLayout>
    );
  }
}
export default FollowPage;
